/*@jsxRuntime classic @jsx React.createElement @jsxFrag React.Fragment*/
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import React from "react";
function _createMdxContent(props) {
  const _components = Object.assign({
    p: "p",
    div: "div"
  }, _provideComponents(), props.components);
  return React.createElement(React.Fragment, null, React.createElement(_components.p, null, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<span\n      class=\"gatsby-resp-image-wrapper\"\n      style=\"position: relative; display: block; margin-left: auto; margin-right: auto; max-width: 531px; \"\n    >\n      <span\n    class=\"gatsby-resp-image-background-image\"\n    style=\"padding-bottom: 116.86746987951808%; position: relative; bottom: 0; left: 0; background-image: url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAAXABQDASIAAhEBAxEB/8QAGAABAQEBAQAAAAAAAAAAAAAAAAMCBQT/xAAXAQEBAQEAAAAAAAAAAAAAAAACAAED/9oADAMBAAIQAxAAAAHh76keJg9wHMzKQV//xAAbEAADAAIDAAAAAAAAAAAAAAAAAQIDEgQTIf/aAAgBAQABBQLWmddFRkunSQ6V0cjS2o1Xp//EABYRAQEBAAAAAAAAAAAAAAAAABEAEP/aAAgBAwEBPwFnf//EABkRAAIDAQAAAAAAAAAAAAAAAAABEBESE//aAAgBAgEBPwHbOjLcf//EABoQAAIDAQEAAAAAAAAAAAAAAAABESExEEH/2gAIAQEABj8CwtUTHElnvFCgo0//xAAcEAEAAgIDAQAAAAAAAAAAAAABABEhQTFRYYH/2gAIAQEAAT8hOImAOU2YodOi+J23wly+VpmNyxDriH6GWQqP/9oADAMBAAIAAwAAABCzP/z/xAAYEQEAAwEAAAAAAAAAAAAAAAABABARUf/aAAgBAwEBPxDXJuKtf//EABkRAQACAwAAAAAAAAAAAAAAAAEAERAhMf/aAAgBAgEBPxAd5RjWNyif/8QAIBABAAICAgEFAAAAAAAAAAAAAQARITFBUWGBkaGx0f/aAAgBAQABPxBinnohdLZQ0QhF8IcDR7TDKDyV+SsAKRwa+YXLAvpAn0xBQ141F1BUBeOfqVRwrreZ/9k='); background-size: cover; display: block;\"\n  ></span>\n  <img\n        class=\"gatsby-resp-image-image\"\n        alt=\"golf origin\"\n        title=\"golf origin\"\n        src=\"/static/990daef9b5ee50990e7827255398996b/ecbae/golf-club.jpg\"\n        srcset=\"/static/990daef9b5ee50990e7827255398996b/4d5fb/golf-club.jpg 166w,\n/static/990daef9b5ee50990e7827255398996b/558f0/golf-club.jpg 333w,\n/static/990daef9b5ee50990e7827255398996b/ecbae/golf-club.jpg 531w\"\n        sizes=\"(max-width: 531px) 100vw, 531px\"\n        style=\"width:100%;height:100%;margin:0;vertical-align:middle;position:absolute;top:0;left:0;\"\n        loading=\"lazy\"\n        decoding=\"async\"\n      />\n    </span>"
    }
  })), "\n", React.createElement(_components.p, null, "Originating in Scotland in the late 1400’s the modern version of the game we now know as golf began in an area of Scotland known as Fife."), "\n", React.createElement(_components.p, null, "Fife is, not surprisingly, where St. Andrews golf course is located and is known by many as the Home of Golf however the links at Musselburgh is considered the oldest golf course in the world according to Guinness."), "\n", React.createElement(_components.p, null, "The actual origin of the word is, like many words, debatable."), "\n", React.createElement(_components.p, null, "Many will often incorrectly quote a theory that the term is actually an acronym for “Gentlemen Only, Ladies Forbidden” however a more probable explanation relates to a game that pre-dated golf invented by the Dutch."), "\n", React.createElement(_components.p, null, "In the Netherlands, a game involving a stick and leather ball was played in the late 1200’s."), "\n", React.createElement(_components.p, null, "The Dutch called this game Chole and later combined this game with a French game called Jeu de mail to produce “Colf”."), "\n", React.createElement(_components.p, null, "The game was very popular and made its way to Scotland via regular trade between Netherlands and Scotland."), "\n", React.createElement(_components.p, null, "The Scots changed a few aspects of the game to produce the modern version of what golf is now and the name evolved into “golf” through language and pronunciation differences between cultures."));
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? React.createElement(MDXLayout, props, React.createElement(_createMdxContent, props)) : _createMdxContent(props);
}
export default MDXContent;
